const Inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6943082",
    space_js: "//zfkpybq.yqzktech.com/common/k-f/static/e/resource/nbxl/hu.js"
  }
];

const Native = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6943083",
    space_js: "//zfkpybq.yqzktech.com/site/lgf_oc/resource/y/openjs/mi/static/y.js"
  }
];

const Banner = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6943084",
    space_js: "//zfkpybq.yqzktech.com/site/mhgp_d/common/zn/production/jd.js"
  }
];

export default {
  Home_inter: Inter,
  Home_native: Native,
  Home_banner: Banner,
  Detail_inter: Inter,
  Detail_native: Native,
  Detail_banner: Banner,
  List_native: Native,
  List_banner: Banner,
  Result_inter: Inter,
  Result_native: Native,
  Result_banner: Banner
};
