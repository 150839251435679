<template>
  <div class="fixed-back-home" @click="onBackHome" v-click:oeReport.action="'FixedBackHomeBtn'">
    <span>回到首页</span>
  </div>
</template>

<script lang="ts">
export default {
  name: "FeaturedTag"
};
</script>

<script lang="ts" setup>
import router from "@/router";
import { useRoute } from "vue-router";

const route = useRoute();

const onBackHome = () => {
  if (route.meta?.backHomeName) {
    return router.replace({ name: route.meta.backHomeName as string });
  }

  router.replace("/home");
};
</script>

<style lang="less">
.fixed-back-home {
  position: fixed;
  right: 0;
  bottom: 36vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  padding: 20px 10px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  background: linear-gradient(rgba(#92f6ff, 1) 0%, rgba(#ac97ff, 0.7) 100%);
  z-index: 2;
  span {
    color: #ffffff;
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
  }
  &:active {
    transform: scale(0.99);
  }
}
</style>
