import { createRouter, createWebHashHistory, START_LOCATION } from "vue-router";

import routes, { pathNames } from "./routes";
import { Haa, OeReportType, OeReportSubType } from "@haa/fwk-h5";
import bus from "@haa/fwk-h5/lib/bus";

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    // 解决路由跳转后 会滚动到底部
    // keep-alive 返回缓存页面后记录浏览位置
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
    }
    // 异步滚动操作
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 });
      }, 0);
    });
  },
  routes
});

router.beforeEach((to, from, next) => {
  if (from == START_LOCATION) {
    to.params.first = "1";
  }

  next();
});

router.afterEach((to, from) => {
  // if (to.meta.title) {
  //   document.title = to.meta.title as string;
  // }
});

export { routes, pathNames };

export default router;
